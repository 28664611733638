<template>
    <div>
        <form :action="action" :id="id_form" method="post" target="_blank">
            <input type="hidden" :name="hidden_input_1" value="" :id="hidden_input_1">
            <input type="hidden" :name="hidden_input_2" :value="value" :id="hidden_input_2">
        </form>
    </div>
</template>

<script>
export default {
    name: "FormSegundaVia",
    props: {
        id_form: {
            type: String,
            default: ""
        },
        action: {
            type: String,
            default: ""
        },
        hidden_input_1: {
            type: String,
            default: ""
        },
        hidden_input_2: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        }
    },
}
</script>