<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <span class="col-sm-12 col-md-2">
          <i class="icon-list"></i> 2ª Via de Boletos
        </span>
      </b-card-header>
      <b-card-body>
        <b-container>
            <b-row>
                <b-col md="6">
                    <b-input-group>
                    <b-form-select
                        v-model="selected_value"
                        :options="options"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                    ></b-form-select>
                    </b-input-group>
                </b-col>
                <i style="display:none;" @click="limpaFiltro" class="fa fa-times fa-lg icone-x" id="icone-x"></i>
                <b-col md="6">
                    <b-input-group>
                    <b-form-input v-on:keyup.enter="filterLista" v-model="filter"/>
                    <b-input-group-append>
                        <b-button variant="primary" @click="filterLista()">
                        <i class="fa fa-search"></i>
                        </b-button>
                    </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-table-simple class="mt-2 font-12" style="margin-bottom: 0;" hover striped small responsive bordered stacked="sm">
                <b-thead>
                    <b-tr>
                        <th class="text-center data_vencimento white-space">
                            Data Vencimento
                            <a class="cursor" @click="filterLista('DT_VENCIMENTO')">
                                <i class="fa fa-sort"></i>
                            </a>
                        </th>
                        <th class="text-center data_emissao white-space">
                            Data Emissão
                            <a class="cursor" @click="filterLista('DT_EMISSAO')">
                                <i class="fa fa-sort"></i>
                            </a>
                        </th>
                        <th class="text-center cnpj white-space">CNPJ Cedente</th>
                        <th class="text-center cnpj white-space">
                            CNPJ Sacado
                            <a class="cursor" @click="filterLista('cliente_cnpj')">
                                <i class="fa fa-sort"></i>
                            </a>
                        </th>
                        <th class="text-center id_nota white-space">
                            Nº Doc-Nº Parc.
                            <a class="cursor" @click="filterLista('ID_NOTA_NR_PARCELA')">
                                <i class="fa fa-sort"></i>
                            </a>
                        </th>
                        <th class="text-center nosso_numero white-space">
                            Nosso Número
                            <a class="cursor" @click="filterLista('NOSSO_NUMERO')">
                                <i class="fa fa-sort"></i>
                            </a>
                        </th>
                        <th class="text-center cliente white-space">
                            Cliente
                            <a class="cursor" @click="filterLista('cliente_codigo')">
                                <i class="fa fa-sort"></i>
                            </a>
                        </th>
                        <th class="text-center valor white-space">
                            Valor
                            <a class="cursor" @click="filterLista('VL_RECEBER')">
                                <i class="fa fa-sort"></i>
                            </a>    
                        </th>
                        <th class="text-center banco">Banco</th>
                        <th class="text-center navegadores">Navegadores Homologados</th>
                    </b-tr>
                </b-thead>
                <b-tbody v-if="this.searchResult  && this.fullList.length > 0">
                    <b-tr v-for="(result, index) in itemsRow" :key="index">
                        <b-td class="text-center estilo-td">{{ result.DT_VENCIMENTO }}</b-td>
                        <b-td class="text-center estilo-td">{{ result.DT_EMISSAO }}</b-td>
                        <b-td class="text-center estilo-td white-space"> 02.457.533/0001-22 </b-td>
                        <b-td class="text-center estilo-td white-space">{{ result.cliente_cnpj | cnpj }}</b-td>
                        <b-td class="text-center estilo-td white-space">{{ result.ID_NOTA_NR_PARCELA }}</b-td>
                        <b-td class="text-center estilo-td">{{ result.NOSSO_NUMERO }}</b-td>
                        <b-td class="estilo-td">
                            <div class="sem-quebra">{{ result.cliente_codigo }}</div>
                        </b-td>
                        <b-td class="estilo-td">
                            <div class="money-div">
                                <span>R$</span>
                                <span style="float: right;"> {{ result.VL_RECEBER | money}}</span>
                            </div>
                        </b-td>
                        <b-td class="text-right estilo-td">
                            <div v-if="banco_do_brasil.indexOf(result.CD_TIPOCOBRANCA) > -1">
                                <a @click="abrirSegundaViaBB(result)" style="cursor:pointer;" target="_blank">
                                    <img src="../../../assets/img/Banco_do_Brasil.svg" class="icones-banco" alt="">
                                </a>
                            </div>
                            <div v-else-if="itau.indexOf(result.CD_TIPOCOBRANCA) > -1">
                                <a @click="abirSegundaViaItau(result)" :data-chave="chave" style="cursor: pointer;" target="_blank">
                                    <img src="../../../assets/img/Itau.svg" class="icones-banco" alt="">
                                </a>
                            </div>
                            <div v-else-if="santander.indexOf(result.CD_TIPOCOBRANCA) > -1">
                                <a @click="abirSegundaViaSantander(result)" style="cursor: pointer;" target="_blank">
                                    <img src="../../../assets/img/Santander.svg" class="icones-banco" alt="">
                                </a>
                            </div>
                        </b-td>
                        <b-td class="text-center estilo-td">
                            <span v-if="santander.indexOf(result.CD_TIPOCOBRANCA) > -1">
                                <img class="navegadores-icone mr-1" src="../../../assets/img/internet-explorer.svg" alt="">   
                            </span>
                            <span v-else-if="itau.indexOf(result.CD_TIPOCOBRANCA) > -1 || banco_do_brasil.indexOf(result.CD_TIPOCOBRANCA) > -1">
                                <img class="navegadores-icone mr-1" src="../../../assets/img/internet-explorer.svg" alt="">
                                <img class="navegadores-icone mr-1" src="../../../assets/img/chrome.svg" alt="">
                                <img class="navegadores-icone" src="../../../assets/img/firefox.svg" alt="">
                            </span>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <div v-if="!this.searchResult" class="nenhum-dado">
                <span colspan="10" class="text-center sem-borda"> Nenhum resultado encontrado </span>
            </div>
            <hr />

            <b-row v-if="this.searchResult && this.fullList.length > 0">
                <b-col>
                    Pagina {{ this.currentPage }} de {{ this.totalPagina }}, resultado
                    {{ this.resultPorPagina }} de {{ this.totalResults}}
                </b-col>
                <b-col>
                    <b-pagination
                    v-model="currentPage"
                    :total-rows="totalResults"
                    :per-page="perPage"
                    class="mt-4"
                    aria-controls="my-table"
                    >
                    <template v-slot:first-text>
                        <span class="text-primary">
                        <i class="fa fa-step-backward"></i>
                        </span>
                    </template>
                    <template v-slot:prev-text>
                        <span class="text-primary">
                        <i class="fa fa-chevron-left"></i>
                        </span>
                    </template>
                    <template v-slot:next-text>
                        <span class="text-primary">
                        <i class="fa fa-chevron-right"></i>
                        </span>
                    </template>
                    <template v-slot:last-text>
                        <span class="text-primary">
                        <i class="fa fa-step-forward"></i>
                        </span>
                    </template>
                    <template v-slot:ellipsis-text>
                        <span>
                        <i class="fa fa-ellipsis-h"></i>
                        </span>
                    </template>
                    <template v-slot:page="{ page, active }">
                        <b v-if="active">{{ page }}</b>
                        <i v-else>{{ page }}</i>
                    </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </b-container>
      </b-card-body>
    </b-card>

    <form-segunda-via
        action="https://www.santandernegocios.com.br/YU_PortalCash/ASP/yu_autenticacaocob.asp"
        id_form="form_santander"
        hidden_input_1="STRCODANCORA"
        hidden_input_2="STRDADOS"
        value=""
    />

     <form-segunda-via
        action="https://ww2.itau.com.br/2viabloq/pesquisa.asp"
        id_form="form_itau"
        hidden_input_1="dc"
        hidden_input_2="msg"
        value="S"
    />

  </div>
</template>

<style scoped>
    .data_vencimento {
        width: 5% !important;
    }

    .data_emissao { 
        width: 5% !important;
    }

    .cnpj{
        width: 16% !important;
    }

    .id_nota{
        width: 17% !important;
    }

    .nosso_numero{
        width: 8% !important;
    }

    .cliente{
        width: 20% !important;
        font-size: 11px !important;
    }

    .valor{
        width: 16% !important;
    }

    .banco{
        width: 10% !important;
    }

    .navegadores{
        width: 10% !important;
    }

    .font-12 { 
        font-size: 12px;
    }

    .estilo-td { 
        vertical-align: top;
        padding: 0.6rem;
    }

    .navegadores-icone {
        width: 1.6em;
        height: 1.6em;
    }

    .icones-banco {
        width: 30px;
        height: 30px;
    }

    .sem-quebra {
        max-width: 185px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .nenhum-dado{
        display: flex;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.05);
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .white-space {
        white-space: nowrap;
    }

    .cursor{
        cursor: pointer;
    }

    .money-div { 
        display: flex;
        justify-content: space-between;
    }

    .icone-x {
    position: absolute;
    margin-top: 0.5em;
    margin-left: 57em;
    z-index: 10;
    cursor: pointer;
  }

  @media screen and (max-width: 1460px) {
  .icone-x {
    margin-left: 57em;
  }
}

@media screen and (max-width: 1420px) {
  .icone-x {
    margin-left: 55.8em;
  }
}

@media screen and (max-width: 1400px) {
  .icone-x {
    margin-left: 54.8em;
  }
}

@media screen and (max-width: 1380px) {
  .icone-x {
    margin-left: 53.8em;
  }
}

@media screen and (max-width: 1365px) {
  .icone-x {
    margin-left: 53em;
  }
}

@media screen and (max-width: 1280px) {
  .icone-x {
    margin-left: 48em;
  }
}

@media screen and (max-width: 1024px) {
  .icone-x {
    margin-left: 34.6em;
  }
}

</style>

<script>
import Revenda from "../../../services/revenda";
import MyDataTable from "@/components/ui/MyDataTable";
import FormSegundaVia from "./FormSegundaVia.vue";

export default {
    name: "SegundaVia",
    components: { MyDataTable,FormSegundaVia },
    data () {
        return {
            searchResult    : new Object(),
            fullList        : [],
            banco_do_brasil : [2, 3, 4, 7, 8, 9, 10, 22],
            itau            : [11, 15, 19, 21, 23],
            santander       : [16, 18],
            filter          : '',
            chave           : '',
            searchResult    : '',
            selected_value  : 10,
            page            : 1,
            currentPage     : 1,
            resultPorPagina : 10,
            perPage         : 10,
            totalPagina     : 0,
            totalResults    : 0,
            ordernacao      : false,  
            options         : [
                { item: "10", name: "Mostrar 10 item" },
                { item: "20", name: "Mostrar 20 itens" },
                { item: "30", name: "Mostrar 30 itens" },
                { item: "40", name: "Mostrar 40 itens" },
                { item: "50", name: "Mostrar 50 itens" }
            ],
        }
    },
    beforeMount() {
        this.loadBudgets();
    },
    methods: {
        loadBudgets() {
            Revenda.getSegundaViaBoletos(this.$store.state.login.resale.revenda_codigo)
                .then(response => {
                    this.searchResult         = response.data.data;
                    this.searchResult.search  = null;
                    this.totalResults         = response.data.data.length;
                    this.searchResult.page    = 1;
                    this.searchResult.results  = response.data.data;
                    this.fullList = response.data.data;
                    this.totalPagina          = Math.ceil(this.fullList.length / this.perPage);
                })
                .catch(e => {
                if ( e.response && e.response.status && e.response.status === 404 && e.response.data)
                {
                    this.searchResult = e.response.data.data;
                } else {
                    this.$helper.showErrorResponse(e);
                }          
            });
        },
        montarFiltro(value)
        {
            let filtroTexto    = this.filter.toUpperCase();
            value.cliente_cnpj = value.cliente_cnpj.toString().replace(".","");

            let exibeRegistro = String(value.DT_VENCIMENTO).toUpperCase().includes(filtroTexto) 
                            || String(value.DT_EMISSAO).toUpperCase().includes(filtroTexto) 
                            || String(value.cnpj).toUpperCase().startsWith(filtroTexto)
                            || String(value.cliente_cnpj).toUpperCase().startsWith(filtroTexto)
                            || String(value.ID_NOTA_NR_PARCELA).toUpperCase().startsWith(filtroTexto)
                            || String(value.NOSSO_NUMERO).toUpperCase().includes(filtroTexto)
                            || String(value.cliente_codigo).toUpperCase().includes(filtroTexto)
                            || String(value.VL_RECEBER).toUpperCase().startsWith(filtroTexto)
                            || String(value.CD_TIPOCOBRANCA).toUpperCase().startsWith(filtroTexto)
            return exibeRegistro;
        },
        getListaFiltrada() {
            return this.searchResult.results.filter(this.montarFiltro);
        },
        filterLista(origem)
        {
            let lista_aux = this.searchResult.results;
           
            lista_aux = this.getListaFiltrada();

            
            this.totalResults = lista_aux.length;
            this.currentPage = 1;
            if(Math.round(lista_aux.length / this.perPage) == 0)
            {
                this.resultPorPagina = lista_aux.length;
                this.totalPagina     = 1;
            }else
            { 
                if(this.resultPorPagina > 10 && this.perPage * this.currentPage < lista_aux.length)
                {
                    this.resultPorPagina = this.currentPage * this.perPage;
                }

                if(this.perPage * this.currentPage < lista_aux.length)
                {
                   this.resultPorPagina = this.perPage;
                }
                
                this.totalPagina = this.selected_value > 10 ? Math.ceil(lista_aux.length / this.selected_value) : Math.ceil(lista_aux.length / this.perPage);
            }

            if(origem !== undefined && origem !== null)
            {
                lista_aux = this.ordernarLista(lista_aux, origem);
            }

            let icone    = document.getElementById('icone-x');
            icone.style.display="block";

            this.fullList = lista_aux;
        },
        limpaFiltro()
        {
            this.filter = '';
            let icone    = document.getElementById('icone-x');
            icone.style.display="none";
            this.loadBudgets();
        },
        reload()
        {
        location.reload();
        },
        ordernarLista(lista, origem)
        {
            return this.orderna(lista, origem);
        },
        orderna(lista, coluna)
        {
            let vue = this;
            if(this.ordernacao)
            {
                lista.sort(function (a, b) {
                    let objComparacao = vue.retornarObjetosComparacaoLista(a, b, coluna);
                    return objComparacao.valorA > objComparacao.valorB ? 1 : -1;
                })
            }else
            {
                lista.sort(function (a, b) {
                    let objComparacao = vue.retornarObjetosComparacaoLista(a, b, coluna);
                    return objComparacao.valorB > objComparacao.valorA ? 1 : -1;
                });
            }
            this.ordernacao = !this.ordernacao;
            return lista;
        },
        retornarObjetosComparacaoLista(a, b, campo)
        {
            let objRetorno = {};
            if(campo == 'DT_EMISSAO' || campo == 'DT_VENCIMENTO')
            {
                a.valorA = this.retornarDataEmMilesecond(a[campo]);
                b.valorB = this.retornarDataEmMilesecond(b[campo]);
            }else if(campo == 'cliente_codigo')
            {
                a.valorA = a[campo].split("- ").pop();
                b.valorB = b[campo].split("- ").pop();
            }else
            {
                a.valorA = a[campo];
                b.valorB = b[campo];
            }

            objRetorno.valorA = a.valorA;
            objRetorno.valorB = b.valorB;
            return objRetorno;
        },
        retornarDataEmMilesecond(data)
        {
            let dataRetorno = data.split("/");
            return new Date(dataRetorno[2] , dataRetorno[1] , dataRetorno[0]).getTime();
        },
        abrirSegundaViaBB(item)
        {
           window.open(`https://www63.bb.com.br/portalbb/boleto/boletos/hc21e,802,3322,10343,2,0,1.bbx?cpfCnpjPagador=${item.cliente_cnpj}&lnDigtvl=${item.NOSSO_NUMERO}&cpfCnpjbeneficiario=02457533000122`);
        },
        abirSegundaViaSantander(item)
        {
            if(!document.documentMode)
            {
                return alert('É permitido abrir os boletos do "Santander" apenas com o Internet Explorer.');         
            }else
            {
                Revenda.getUrlSegundaViaSantander(item)
                    .then(response => {
                        document.getElementById('STRCODANCORA').value=response.data.data.codigoCifrado;
                        document.getElementById('STRDADOS').value=response.data.data.dadosCifrado;
                        document.getElementById('form_santander').submit();
                    })
                    .catch(e => {
                    if (e.response.status === 400 || e.response.status === 404)
                    {
                        this.$helper.showErrorResponse(e);
                    }          
                });
            }
        },
        abirSegundaViaItau(item)
        {
            Revenda.getUrlSegundaViaItau(item)
                .then(response => {
                    let chave   = response.data.data;
                    var nrdoc   = item.NR_DOCUMENTO;
                    var parcela = item.NR_PARCELA;
                    var cod     = item.codigo;
                    document.getElementById('dc').value=chave;
                    document.getElementById('form_itau').submit();
                })
                .catch(e => {
                if (e.response.status === 404)
                {
                    this.$helper.showErrorResponse(e);
                }          
            });
        } 
    },
    computed: {
        itemsRow()
        {
            let indice = (this.currentPage * this.perPage) - this.perPage;
            
            if(this.currentPage == 1)
            {
                this.resultPorPagina = this.currentPage * this.perPage;
                return this.fullList.slice(0, this.perPage);
            }else
            {
                if(this.currentPage * this.perPage > this.fullList.length)
                {
                    this.resultPorPagina = this.fullList.length;
                }  
                else
                {
                    this.resultPorPagina = this.currentPage * this.perPage;
                }
                return this.fullList.slice(indice, (this.currentPage * this.perPage));
            }
        }
    },
    watch: {
        currentPage(currentPage) 
        {
            if (currentPage) {
                this.page = currentPage;
            }
        },
        selected_value(novo_valor)
        {
            if(novo_valor)
            {
                this.perPage         = novo_valor;
                this.resultPorPagina = this.perPage;
                this.totalPagina     = novo_valor > 10 ? Math.ceil(this.fullList.length / novo_valor) : Math.ceil(this.fullList.length / this.perPage);
            }
        },
    }
}
</script>